<template>
  <base-layout>
    <item-list></item-list>  
  </base-layout>
</template>

<script>
import ItemList from '../components/item/ItemList.vue';
import { mapGetters, mapActions} from 'vuex';
export default {
 name: 'items-page',
 components: {
  ItemList
 },
 mounted() {
   this.retrieveItems();
 },
 computed: {
        ...mapGetters('item',['items']),
    },
    methods: {
        ...mapActions('item',['retrieveItems']),
    }

}
</script>

<style scoped>

</style>