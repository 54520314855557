<template>
  <ion-list>
    <item-list-item v-for="item in items" :key="item.id" :item="item"></item-list-item>  
  </ion-list>
</template>

<script>
import { IonList} from '@ionic/vue';
import ItemListItem from './ItemListItem.vue';
import { mapGetters } from 'vuex';
export default {
    nam: 'item-list',
    components: {
        IonList,
        ItemListItem
    },
    computed: {
        ...mapGetters('item',['items'])
    }
}
</script>

<style>

</style>